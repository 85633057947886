import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from '../../../../../components/Grid';
import { Switch } from '../../../../../v4/components';
import {
  AutoComplete, CustomSelect, Input
} from '../../../../../components';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';

const propTypes = {
  disbursement: PropTypes.shape({
    dimension: PropTypes.string,
    value: PropTypes.number,
    FreeSKUs: PropTypes.instanceOf(Array),
    allowMultiple: PropTypes.bool,
    searchText: PropTypes.string.isRequired,
    catalogId: PropTypes.number,
  }).isRequired,
  onInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  onDropDownChange: PropTypes.func.isRequired,
  onAutoCompleteChange: PropTypes.func.isRequired,
  catalogChildrenList: PropTypes.instanceOf(Array),
  refsObj: PropTypes.instanceOf(Object).isRequired,
  catalogList: PropTypes.instanceOf(Array).isRequired,
  criteria: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  enableErrorDisplay: true,
  catalogChildrenList: [],
  onInputChange: () => null,
};

const disbursementTypeList = [
  {
    id: 1,
    title: 'Free SKU (Same BU)',
    value: 'FREE_SKU',
  },
  {
    id: 2,
    title: 'Discount (%)',
    value: 'PERCENT',
  },
  {
    id: 3,
    title: 'Amount',
    value: 'AMOUNT',
  },
];

const View = ({ ...props }) => {
  const {
    disbursement,
    onInputChange,
    onDropDownChange,
    onAutoCompleteChange,
    catalogChildrenList,
    enableErrorDisplay,
    refsObj,
    criteria,
    enableOutletTarget,
  } = props;
  const displayAllowMultipleSwitch = (disbursementType) => {
    switch (disbursementType) {
    case 'FREE_SKU':
      return true;
    case 'AMOUNT':
      if (criteria?.dimension === 'QUANTITY' && criteria?.type === 'NORMAL') {
        return true;
      }
    default:
      return false;
    }
  };

  const filteredDisbursementList =
    criteria.type === 'CURRENT_BILL' || enableOutletTarget
      ? disbursementTypeList.filter((list) => list.value !== 'FREE_SKU')
      : disbursementTypeList;

  return (
    <div className='disbursement'>
      <Row>
        <Col md={8}>
          <Row>
            <Col md={4}>
              <div>
                <label>Disbursement Type</label>
                <CustomSelect
                  enableValidation
                  options={filteredDisbursementList}
                  className='custom-select'
                  placeholder='Select Catalog'
                  getOptionValue={({ id }) => id}
                  getOptionLabel={({ title }) => title}
                  enableErrorDisplay
                  onChange={(event) => onDropDownChange(event.value, ['dimension'])}
                  value={filteredDisbursementList.filter(({ value }) => value === disbursement.dimension)}
                />
              </div>
            </Col>
            <Col md={4} className=''>
              <Input
                name='value'
                type='number'
                enableValidation
                value={disbursement.value}
                labelContent='Value'
                enableErrorDisplay={enableErrorDisplay}
                onChange={(event) => onInputChange(event)}
                argument={{
                  min: 1,
                  allow_leading_zeroes: false,
                }}
                ref={(ref) => (refsObj.value = ref)}
              />
            </Col>
          </Row>

          {disbursement.dimension === 'FREE_SKU' && (
            <Row>
              <Col md={8} className='mt-24'>
                <label>Applicable SKUs</label>
                <AutoComplete
                  dropDownList={catalogChildrenList}
                  name='freeSKUId'
                  placeholder='Search'
                  selectedDropDowns={disbursement.freeSKUs}
                  onDropDownSelection={onAutoCompleteChange}
                  searchText={disbursement.searchText}
                />
              </Col>
              <Col md={2} />

            </Row>
          )}
          {displayAllowMultipleSwitch(disbursement.dimension) && (
            <Col md={6}>
              <div className='mt-24'>
                <div className='status-switch'>
                  <Switch
                    onToggle={(checked, event) => {
                      onInputChange({
                        formattedValue: !!checked,
                        target: {
                          name: 'allowMultiple',
                          type: 'boolean',
                          value: !!checked,
                        },
                      });
                    }}
                    checked={disbursement.allowMultiple}
                    name='allowMultiple'
                    labelContent='Allow Multiple Selection'
                  />
                </div>
              </div>
            </Col>
          )}
        </Col>
      </Row>
    </div>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const ViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default ViewWithErrorAndLoading;
