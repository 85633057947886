import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input } from '../index';
import { Menu, Icon } from '../../v4/components';
import { LabelStyled, MessageStyled } from '../Input/TextStyled';
import { EMPTY as EMPTY_ERROR } from '../../data/enums/ErrorMessage';

const propTypes = {
  asyncMode: PropTypes.bool,
  searchText: PropTypes.string,
  dropDownUpdater: PropTypes.func,
  dropDownValueKey: PropTypes.string,
  name: PropTypes.string,
  onDropDownSelection: PropTypes.func,
  dropDownDisplayKey: PropTypes.string,
  dropDownList: PropTypes.instanceOf(Array),
  selectedDropDowns: PropTypes.instanceOf(Array),
  labelContent: PropTypes.string,
  placeholder: PropTypes.string,
  newClassName: PropTypes.string,
  errorMessage: PropTypes.string,
  enableValidation: PropTypes.bool,
  enableErrorDisplay: PropTypes.bool,
  searchIcon: PropTypes.bool,
};

const defaultProps = {
  dropDownUpdater: () => null,
  dropDownValueKey: 'id',
  dropDownDisplayKey: 'title',
  name: 'name',
  errorMessage: EMPTY_ERROR,
  dropDownList: [
    {
      id: 1,
      title: 'one',
      value: 'Nepal',
    },
    {
      id: 2,
      title: 'two',
      value: 'Nepal',
    },
    {
      id: 3,
      title: 'three',
      value: 'Nepal',
    },
    {
      id: 4,
      title: 'four',
      value: 'Nepal',
    },
    {
      id: 5,
      title: 'five',
      value: 'Nepal',
    },
    {
      id: 6,
      title: 'six',
      value: 'Nepal',
    },
  ],
  searchText: '',
  asyncMode: false,
  selectedDropDowns: [],
  onDropDownSelection: () => null,
  labelContent: '',
  placeholder: '',
  newClassName: '',
  searchIcon: false,
  enableValidation: true,
  enableErrorDisplay: false,
};

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: props.searchText,
    };

    this.reference = {
      autoComplete: React.createRef(),
    };
  }

  handleInputChange = (event, label = 'searchText') => {
    const { asyncMode, dropDownUpdater } = this.props;
    this.setState({ searchText: event.target.value });

    if (asyncMode) {
      // trigger the call back TODO
      dropDownUpdater(event.target.value);
    }
  };

  getDropDownListForUserInput = () => {
    const { searchText } = this.state;
    const { selectedDropDowns, dropDownDisplayKey, dropDownList, asyncMode, dropDownValueKey } = this.props;

    const validDropDownCriteria = el => {
      const searchCriteria = el[dropDownDisplayKey]?.toLowerCase()?.includes(searchText?.toLowerCase());

      const unSelectedCriteria = asyncMode
        ? selectedDropDowns?.indexOf(el) === -1
        : selectedDropDowns?.indexOf(el[dropDownValueKey]) === -1;

      return searchCriteria && unSelectedCriteria;
    };

    const unselectedDropDown = dropDownList.filter(validDropDownCriteria) || [];

    return unselectedDropDown;
  };

  handleCrossIconClick = menu => {
    const { selectedDropDowns, onDropDownSelection, dropDownValueKey, asyncMode, name } = this.props;

    const indexInSelectedList = selectedDropDowns.findIndex(
      el => (asyncMode ? el[dropDownValueKey] : el) === menu[dropDownValueKey],
    );

    selectedDropDowns.splice(indexInSelectedList, 1);
    onDropDownSelection(selectedDropDowns, name);
  };

  onDropDownSelection = dropDown => {
    this.setState({ searchText: '' });
    const { selectedDropDowns, onDropDownSelection, asyncMode, dropDownValueKey, name } = this.props;

    if (asyncMode) {
      selectedDropDowns.push(dropDown);
    } else {
      selectedDropDowns.push(dropDown[dropDownValueKey]);
    }
    onDropDownSelection(selectedDropDowns, name);
  };

  getSelectedDropDowns = () => {
    const { selectedDropDowns, asyncMode, dropDownList, dropDownValueKey, dropDownDisplayKey } = this.props;

    if (asyncMode) {
      return selectedDropDowns;
    }

    const transformedDropDownList = [];

    selectedDropDowns?.forEach(id => {
      const name = dropDownList.find(element => element[dropDownValueKey] === id);
      if (name) {
        transformedDropDownList.push(name);
      }
    });

    return transformedDropDownList;
  };

  render() {
    const { searchText } = this.state;
    const {
      dropDownDisplayKey,
      labelContent,
      placeholder,
      errorMessage,
      enableValidation,
      enableErrorDisplay,
      newClassName,
      searchIcon,
    } = this.props;
    const selectedDropDowns = this.getSelectedDropDowns();

    const updatedDropDownList = this.getDropDownListForUserInput();
    const displayError = enableValidation && enableErrorDisplay;

    const menuHeader = (
      <>
        {labelContent !== undefined ? <LabelStyled>{labelContent}</LabelStyled> : ''}
        <Input
          name="promotionId"
          type="text"
          value={searchText}
          placeholder={placeholder}
          autoComplete="off"
          onChange={event => this.handleInputChange(event, 'searchText')}
        />
        {searchIcon && (
          <div className="search">
            <Icon small iconName="search" />
          </div>
        )}
      </>
    );
    return (
      <MenuHeaderStyled>
        <Menu block header={menuHeader} hideOnClick ref={this.reference.autoComplete}>
          {updatedDropDownList.length > 0 && (
            <div className="autocomplete-list">
              {updatedDropDownList.map((dropDown, index) => (
                <div
                  key={`selection-${dropDownDisplayKey ? `${dropDown[dropDownDisplayKey]}-${index}` : index}`}
                  onClick={() => this.onDropDownSelection(dropDown)}
                >
                  <span>{dropDown[dropDownDisplayKey]}</span>
                </div>
              ))}
            </div>
          )}
        </Menu>
        <div className={`suggested-autocomplete-list-wrap ${newClassName}`}>
          {selectedDropDowns.map((item, index) => (
            <div className="suggested-autocomplete-list" key={index}>
              <span className="text">{item[dropDownDisplayKey]}</span>
              <span className="ic-close" onClick={() => this.handleCrossIconClick(item)} />
            </div>
          ))}
          {displayError && selectedDropDowns && selectedDropDowns.length === 0 && (
            <MessageStyled className="form-error">
              <span className="error-message">
                <Icon iconName="exclamation-full" />
                {errorMessage}
              </span>
            </MessageStyled>
          )}
        </div>
      </MenuHeaderStyled>
    );
  }
}

const MenuHeaderStyled = styled.div`
  .search {
    position: absolute;
    right: 8px;
    padding-top: 8px;
    color: hsl(0, 0%, 50%);
  }
`;

AutoComplete.propTypes = propTypes;

AutoComplete.defaultProps = defaultProps;

export default AutoComplete;
